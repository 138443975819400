<header>
  <phn-header [env]="env.env" [locale]="env.locale" mode="hero"></phn-header>
</header>
<ng-container
  *ngIf="
    (listUnsubscribed$ | async) || (isUnsubscribed$ | async);
    else landingPageTemplate
  "
>
  <app-unsubscribed-newsletter> </app-unsubscribed-newsletter>
</ng-container>
<ng-template #landingPageTemplate>
  <app-unsubscribe-newsletter> </app-unsubscribe-newsletter>
</ng-template>
<footer>
  <pnav-footer [env]="env.env" [locale]="env.locale" />
</footer>
