import { Component, inject } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-unsubscribed-newsletter',
  templateUrl: './unsubscribed-newsletter.component.html'
})
export class UnsubscribedNewsletterComponent {
  private appService = inject(AppService);
  public newsletterType$ = this.appService.newsletterType$;
}
