/**
 * Integration environment configuration
 */
import { userLocale } from '../app/models/locale.model';

export const environment = {
  phraseToken:
    '11fca6e1524f9bc819fff1000654eb12b07b5e5e5d26d84e38c203f255079529',
  locale: userLocale || 'en-GB',
  lambdaEndpoint: 'https://api.unsubscribe-test.porsche.com/',
  env: 'preview'
};
