import { Component, inject } from '@angular/core';
import { AppService } from './app.service';
import { environment } from '../environments/environment';
import { mapLanguageToLocale, userLocale } from './models/locale.model';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private appService = inject(AppService);
  public isUnsubscribed$ = this.appService.isUnsubscribed$;
  public listUnsubscribed$ = this.appService.listUnsubscribed$;
  public env = environment;
  protected translateService = inject(TranslocoService);

  constructor() {
    const lang =
      mapLanguageToLocale(userLocale).language ||
      this.translateService.getDefaultLang();
    this.translateService.setActiveLang(lang);
  }
}
