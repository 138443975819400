<header></header>
<p-flex [direction]="'column'" class="unsubscribe__container--spacing">
  <p-flex-item>
    <p-headline>{{ headline }} </p-headline>
  </p-flex-item>
  <span class="unsubscribe__container--layout-x-large"></span>
  <p-flex-item>
    <p-headline variant="headline-3">{{ subHeadline }}</p-headline>
  </p-flex-item>
  <span class="unsubscribe__container--layout-medium"></span>
  <p-flex-item>
    <p-text>
      {{ description }}
    </p-text>
  </p-flex-item>
  <span class="unsubscribe__container--layout-medium"></span>
  <p-flex direction="row" *ngIf="!isUnsubscribedPage">
    <p-flex-item class="unsubscribe__container--spacing-right">
      <p-text>
        {{ mailAddressRemoval1 }}
      </p-text>
    </p-flex-item>
    <p-flex-item class="unsubscribe__container--spacing-right">
      <p-text weight="semibold">
        {{ email$ | async }}
      </p-text>
    </p-flex-item>
    <p-flex-item>
      <p-text>
        {{ mailAddressRemoval2 }}
      </p-text>
    </p-flex-item>
  </p-flex>
  <span class="unsubscribe__container--layout-x-large"></span>
  <p-flex-item *ngIf="buttonTxt">
    <p-button (click)="onClick()">{{ buttonTxt }}</p-button>
    <p-toast class="toast__element--position"></p-toast>
  </p-flex-item>
</p-flex>
<footer></footer>
