import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.production';

import {
  defineCustomElements as definePorscheNavigation
} from '@pcom-navigate/header/loader';
import { defineCustomElements as definePorscheFooter } from '@pcom-navigate/footer/loader';
import { enableProdMode } from '@angular/core';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

definePorscheNavigation(window);
definePorscheFooter(window);

if (environment.env === 'production') {
  enableProdMode();
}
