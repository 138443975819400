<ng-container *transloco="let t; read: 'unsubscribe'">
  <app-unsubscribe-template-container
    [headline]="
      t('headline', { newsletterType: newsletterType$ | async | transloco })
    "
    [subHeadline]="t('subheadline')"
    [description]="t('description')"
    [buttonTxt]="t('button')"
    [mailAddressRemoval1]="t('mailaddressremoval1')"
    [mailAddressRemoval2]="t('mailaddressremoval2')"
    (onBtnClick)="onUnsubscribe()"
  ></app-unsubscribe-template-container>
</ng-container>
