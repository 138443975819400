import { Component, OnDestroy, inject } from '@angular/core';
import { AppService } from '../../app.service';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-unsubscribe-newsletter',
  templateUrl: './unsubscribe-newsletter.component.html'
})
export class UnsubscribeNewsletterComponent implements OnDestroy {
  private appService = inject(AppService);
  private subs = new SubSink();
  public newsletterType$ = this.appService.newsletterType$;

  onUnsubscribe() {
    this.subs.sink = this.appService.unsubscribeNewsletter().subscribe();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
