<ng-container *transloco="let t; read: 'unsubscribed'">
  <app-unsubscribe-template-container
    [headline]="t('headline')"
    [subHeadline]="t('subheadline')"
    [description]="
      t('description', { newsletterType: newsletterType$ | async | transloco })
    "
    [isUnsubscribedPage]="true"
  ></app-unsubscribe-template-container>
</ng-container>
